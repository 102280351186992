import React from "react"
import Layout from "./share/layout";

const Nivelacion = () => {
  return (
    <Layout>
       <iframe
            allowfullscreen="true"
            width="100%"
            frameBorder="0"
            src='https://ucp-susana-baca.vercel.app/intro'
            title='Arquitecturas de la Imaginación [Expresión Gráfica & Nivelaciones]'

            style={{ height: '100vh' }}
          />
    </Layout>
  )
}

export default Nivelacion